import { Card, Container } from 'react-bootstrap'
import Carousel, { ArrowProps } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import styles from './ApplicationCarousel.module.scss'
import { ResponsiveCarouselConfig } from './ApplicationCarousel.config'

const ApplicationCarousel = (): JSX.Element => {
  return (
    <Container className={styles.applicationCarouselContainer}>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={false}
        swipeable
        draggable
        focusOnSelect={false}
        showDots={false}
        responsive={ResponsiveCarouselConfig}
        infinite={false}
        renderButtonGroupOutside
        shouldResetAutoplay={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        <Container className={styles.applicationCarouselItem}>
          <div
            className={styles.applicationCarouselItemImage}
            style={{ backgroundImage: `url(application-images/1.png)` }}
          ></div>
        </Container>
        <Container className={styles.applicationCarouselItem}>
          <div
            className={styles.applicationCarouselItemImage}
            style={{ backgroundImage: `url(application-images/2.png)` }}
          ></div>
        </Container>
        <Container className={styles.applicationCarouselItem}>
          <div
            className={styles.applicationCarouselItemImage}
            style={{ backgroundImage: `url(application-images/3.png)` }}
          ></div>
        </Container>
      </Carousel>
    </Container>
  )
}
const CustomLeftArrow = ({ onClick }: ArrowProps): JSX.Element => (
  <Container onClick={onClick}>
    <i className={styles.customLeftArrow}></i>
  </Container>
)
const CustomRightArrow = ({ onClick }: ArrowProps): JSX.Element => (
  <Container onClick={onClick}>
    <i className={styles.customRightArrow}></i>
  </Container>
)

export default ApplicationCarousel
