import { Card, Container } from 'react-bootstrap'
import Carousel, { ArrowProps } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import styles from './BecomePartnerCarousel.module.scss'
import { ResponsiveCarouselConfig } from './BecomePartnerCarousel.config'
import classNames from 'classnames'

const BecomePartnerCarousel = (): JSX.Element => {
  return (
    <Container className={styles.partnerCarouselContainer}>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={false}
        swipeable
        draggable
        focusOnSelect={false}
        showDots={false}
        responsive={ResponsiveCarouselConfig}
        infinite={false}
        renderButtonGroupOutside
        shouldResetAutoplay={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        <Container className={styles.partnerCarouselItem}>
          <div>
            <h6 className={styles.sizzlyWineRed}>Mehr Umsatz</h6>
          </div>
          <div
            className={styles.partnerCarouselItemImage}
            style={{ backgroundImage: `url(become-partner-images/ZufriedeneGäste.webp)` }}
          ></div>
          <p className="mt-3 w-75">
            Steigere deinen Umsatz nachhaltig durch eine größere Sichtbarkeit deines Restaurants auf der
            sizzly-Plattform, höhere Durchschnittsbons und zusätzlichem Takeaway-Geschäft.
          </p>
        </Container>
        <Container className={styles.partnerCarouselItem}>
          <div>
            <h6 className={styles.sizzlyWineRed}>Besserer Service</h6>
          </div>
          <div
            className={styles.partnerCarouselItemImage}
            style={{ backgroundImage: `url(become-partner-images/Umsatz.webp)` }}
          ></div>
          <p className="mt-3 w-75">
            Befreie deine Servicekräfte von lästigen Routineaufgaben und ineffizienten Prozessen. Versetze sie in die
            Lage, genau das zu tun, was sie am besten können - den Besuch deiner Gäste zu etwas Besonderem zu machen.
          </p>
        </Container>
        <Container className={styles.partnerCarouselItem}>
          <div>
            <h6 className={styles.sizzlyWineRed}>Zufriedene Gäste</h6>
          </div>
          <div
            className={styles.partnerCarouselItemImage}
            style={{ backgroundImage: `url(become-partner-images/Service.webp)` }}
          ></div>
          <p className="mt-3 w-75">
            Lass deine Gäste nicht länger warten! Biete ihnen zeitgemäße Bestell- und Bezahlmöglichkeiten und hebe dich
            von der Masse ab.
          </p>
        </Container>
      </Carousel>
    </Container>
  )
}
const CustomLeftArrow = ({ onClick }: ArrowProps): JSX.Element => (
  <Container onClick={onClick}>
    <i className={styles.customLeftArrow}></i>
  </Container>
)
const CustomRightArrow = ({ onClick }: ArrowProps): JSX.Element => (
  <Container onClick={onClick}>
    <i className={styles.customRightArrow}></i>
  </Container>
)

export default BecomePartnerCarousel
