import classNames from 'classnames'
import { useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import Carousel, { ArrowProps } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import styles from './OrderTypeCarousel.module.scss'
import {
  OrderTypeCarouselKeyType,
  OrderTypesCarouselItemsConfig,
  OrderTypesCarouselItemsConfigSetup,
  OrderTypesCarouselItemsType,
  OrderTypesCarouselSlideType,
  ResponsiveCarouselConfig,
} from './OrderTypeCarouselItems.config'

const OrderTypeCarousel = ({ orderType }: { orderType: OrderTypeCarouselKeyType }): JSX.Element => {
  let carouselRef: Carousel | null = null
  OrderTypesCarouselItemsConfigSetup()

  useEffect(() => {
    if (carouselRef) {
      carouselRef.goToSlide(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType])

  const orderTypesCarouselSlides = orderType ? (
    (OrderTypesCarouselItemsConfig as OrderTypesCarouselItemsType)[orderType].map(
      (carouselItem: OrderTypesCarouselSlideType, idxItem: number) => (
        <Container className={classNames(styles.orderTypeCarouselItem, 'mb-5 pt-2')} key={`order-slide-${idxItem}`}>
          <Card className={styles.orderTypeCarouselItemCard}>
            <Container className={styles.orderTypeCarouselItemImageContainer}>
              <div
                className={styles.orderTypeCarouselItemImage}
                style={{ backgroundImage: `url(order-types-images/${orderType}/${carouselItem.picId}.png)` }}
              ></div>
            </Container>
            <Card.Body>
              <Card.Text className={styles.orderTypeCarouselItemTextContainer}>
                <Container className={styles.orderTypeCarouselItemText}>{carouselItem.text}</Container>
                <Container className={styles.orderTypeCarouselItemTextDots}>
                  {Array.apply(null, Array(OrderTypesCarouselItemsConfig[orderType].length)).map((item, dotIndex) => (
                    <div
                      key={`order-type-carousel-item-dot-${dotIndex}`}
                      className={classNames(styles.orderTypeCarouselDot, {
                        [styles.orderTypeCarouselDotActive]: dotIndex === idxItem,
                      })}
                    ></div>
                  ))}
                </Container>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      )
    )
  ) : (
    <Container></Container>
  )

  return (
    <Container className={styles.orderTypeCarouselContainer}>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={false}
        swipeable
        draggable
        focusOnSelect={false}
        showDots={false}
        responsive={ResponsiveCarouselConfig}
        infinite={false}
        renderButtonGroupOutside
        shouldResetAutoplay={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        ref={(el: Carousel | null) => (carouselRef = el)}
      >
        {orderTypesCarouselSlides}
      </Carousel>
    </Container>
  )
}
const CustomLeftArrow = ({ onClick }: ArrowProps): JSX.Element => (
  <Container onClick={onClick}>
    <i className={styles.customLeftArrow}></i>
  </Container>
)
const CustomRightArrow = ({ onClick }: ArrowProps): JSX.Element => (
  <Container onClick={onClick}>
    <i className={styles.customRightArrow}></i>
  </Container>
)

export default OrderTypeCarousel
