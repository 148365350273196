import classNames from 'classnames'
import styles from './RestaurantSearch.module.scss'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

export const RestaurantSearch = (url: string, name: string) => {
  const { t } = useTranslation(['homepage'])
  return (
    <form
      method="GET"
      action={`/${url}`}
      className={classNames('d-flex justify-content-center mb-4', styles.searchBoxContainer)}
    >
      <input
        type="text"
        name={`${name}`}
        className={classNames(styles.searchInput)}
        id="masthead-search-input"
        placeholder={t('homepage:searchInput')}
      />
      <button type="submit" className={classNames('p-0', styles.searchButton)}>
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </form>
  )
}
