import { useTranslation } from 'react-i18next'

export type OrderTypeCarouselKeyType = 'inHouse' | 'takeAway' | 'selfService' | 'paymentOnly'
export type OrderTypesCarouselItemsType = {
  [key in OrderTypeCarouselKeyType]: Array<OrderTypesCarouselSlideType>
}
export interface OrderTypesCarouselSlideType {
  picId: number
  text: string
}

export const OrderTypesCarouselItemsConfigSetup = () => {
  const { t } = useTranslation(['homepage'])

  OrderTypesCarouselItemsConfig = {
    inHouse: [
      {
        picId: 1,
        text: t('homepage:scanQRCodeAtTheTable'),
      },
      {
        picId: 2,
        text: t('homepage:selectDesiredItemsFromTheDigitalMenu'),
      },
      {
        picId: 3,
        text: t('homepage:payWithTheDigitalMeansOfPaymentYouWant'),
      },
      {
        picId: 4,
        text: t(
          'homepage:leanBackEnjoyYourFoodAndExperienceYourRestaurantVisitWithoutAnnoyingWaitingTimesForOrderingOrBilling'
        ),
      },
      {
        picId: 5,
        text: t('homepage:pickUpYourOrderAtTheSelectedTimeWeWillAlsoNotifyYouViaSmsAsSoonAsYourOrderIsReady'),
      },
    ],
    takeAway: [
      {
        picId: 1,
        text: t('homepage:openTheDesiredRestaurantViaSizzlyDeOrVisitTheRestaurateurSWebsite'),
      },
      {
        picId: 2,
        text: t('homepage:selectDesiredItemsFromTheDigitalMenu'),
      },
      {
        picId: 3,
        text: t('homepage:specifyThePickUpTimeYouWant'),
      },
      {
        picId: 4,
        text: t('homepage:payWithTheDigitalMeansOfPaymentYouWant'),
      },
      {
        picId: 5,
        text: t('homepage:pickUpYourOrderAtTheSelectedTimeWeWillAlsoNotifyYouViaSmsAsSoonAsYourOrderIsReady'),
      },
    ],
    selfService: [
      {
        picId: 1,
        text: t('homepage:takeASeatDirectlyAtATableWithoutHavingToQueueInAdvance'),
      },
      {
        picId: 2,
        text: t('homepage:scanQRCodeAtTheTable'),
      },
      {
        picId: 3,
        text: t('homepage:selectDesiredItemsFromTheDigitalMenu'),
      },
      {
        picId: 4,
        text: t('homepage:payWithApplePayGooglePayPaypalCreditCardKlarnaSofortüberweisungOrCashPayment'),
      },
      {
        picId: 5,
        text: t('homepage:thenWeWillAlsoNotifyYouBySmsAsSoonAsYourOrderIsReadyYouCanThenPickThemUpAtTheIssue'),
      },
    ],
    paymentOnly: [
      {
        picId: 1,
        text: t('homepage:scanQRCodeAtTheTable'),
      },
      {
        picId: 2,
        text: t(
          'homepage:fromTheOverviewOfAllItemsThatAreBookedOnYourTableSelectThoseThatYouPaySimplySplitTheBillWithYourFriends'
        ),
      },
      {
        picId: 3,
        text: t('homepage:payWithApplePayGooglePayPaypalCreditCardKlarnaSofortüberweisungOrCashPayment'),
      },
      {
        picId: 4,
        text: t('homepage:leaveTheRestaurantRelaxedAtAnyTimeWheneverYouWant'),
      },
    ],
  }
}

export let OrderTypesCarouselItemsConfig: OrderTypesCarouselItemsType

export const ResponsiveCarouselConfig = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 790 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 790, min: 0 },
    items: 1,
  },
}
