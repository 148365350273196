import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import HomepageMasthead from '../components/HomepageMasthead/HomepageMasthead'
import OrderTypeCarousel from '../components/OrderTypeCarousel/OrderTypeCarousel'
import styles from './homepage.module.scss'

import { OrderTypeCarouselKeyType } from '../components/OrderTypeCarousel/OrderTypeCarouselItems.config'
import ApplicationCarousel from '../components/ApplicationCarousel/ApplicationCarousel'
import BecomePartnerCarousel from '../components/BecomePartnerCarousel/BecomePartnerCarousel'

const HomePage = () => {
  const { t } = useTranslation(['homepage'])
  const [orderTypeSwitchIndexSelected, setOrderTypeSwitchIndexSelected] = useState(0)

  const orderTypeSwitchesKeys: Array<OrderTypeCarouselKeyType> = [
    'inHouse',
    /*'takeAway',
    'selfService',*/ 'paymentOnly',
  ]

  return (
    <>
      <HomepageMasthead />
      <Container className={classNames('d-flex text-center flex-column mt-5', styles.functionalityHeading)}>
        <h2 className={classNames(styles.functionalityHeading, 'mb-3 px-4')}>{t('homepage:functionalityHeading')}</h2>
        <h6 className={classNames(styles.functionalityHeading, 'mb-3 px-4')}>
          {t('homepage:functionalityDescription')}
        </h6>
        <p className={classNames(styles.paragraphWidth, 'mb-5 px-5 text-dark m-auto')}>
          {t('homepage:functionalityText')}
        </p>
        <Container className={classNames(' d-flex justify-content-center', styles.orderTypeSwitchesContainer)}>
          {orderTypeSwitchesKeys.map((translationKey, idx) => {
            return (
              <button
                key={translationKey}
                onClick={() => setOrderTypeSwitchIndexSelected(idx)}
                className={classNames(styles.orderTypeSwitch, 'px-3', {
                  [styles.orderTypeSwitchSelected]: orderTypeSwitchIndexSelected === idx,
                })}
              >
                {t(`homepage:${translationKey}`)}
              </button>
            )
          })}
        </Container>
      </Container>
      <Container className={classNames('d-flex text-center flex-column pb-5', styles.contentContainer)}>
        <Container className={styles.contentWrapper}>
          <Container className={classNames('mt-2', styles.contentContainerMainElement)}>
            <OrderTypeCarousel orderType={orderTypeSwitchesKeys[orderTypeSwitchIndexSelected]} />
          </Container>
          <Container className={classNames('mt-5', styles.contentContainerMainElement)}>
            <h2 className={classNames(styles.applicationHeading, 'mb-3 px-4')}>{t('homepage:applicationHeading')}</h2>
            <h6 className={classNames(styles.applicationHeading, 'mb-5 px-4')}>
              {t('homepage:applicationDescription')}
            </h6>
            <ApplicationCarousel />
          </Container>
          <Container className={classNames('text-white px-4 mt-5', styles.contentContainerMainElement)}>
            <h2 className="mb-4">{t('homepage:paymentMethods')}</h2>
            <h6>{t('homepage:paymentMethodsSubheading')}</h6>
            <h6>{t('homepage:availablePaymentMethods')}</h6>
            <div className={classNames(styles.paymentMethodsElement, 'mt-4 m-auto w-100')} />
          </Container>
          <Container className={styles.contentContainerMainElement}></Container>
        </Container>
      </Container>
      <Container className="text-center px-4 mt-5">
        <h2 className={classNames('mb-4', styles.sizzlyWineRed)}>{t('homepage:becomePartner')}</h2>
        <h6 className={classNames('mb-4', styles.sizzlyWineRed)}>{t('homepage:restaurateurQuestion')}</h6>
        <p className="mb-4">
          Dann registriere dich noch heute für sizzly und profitiere von den Vorteilen unserer Self-Ordering- &
          -Payment-Plattform.
        </p>
        <BecomePartnerCarousel />
        <a
          className={classNames(
            'btn mx-auto rounded-pill mt-3 text-uppercase text-light py-2 px-3',
            styles.sizzlyWineRedBtn
          )}
          href="https://gastro.sizzly.de"
        >
          {t('homepage:becomePartner')}
        </a>
      </Container>
    </>
  )
}

export default HomePage
